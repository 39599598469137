import React, { useCallback, useMemo } from 'react';
import PaqueryTable from '@paquery-team/lib-table';

import { useMarketplaceOptions, useStoreOptions } from 'hooks/useOptions';
import { EyeOutlined } from '@ant-design/icons';
import { Tag, theme, Tooltip } from 'antd';
import {
  AssigneeRouteEventType,
  IAssigneeRoute,
  RouteStatus,
  useAssigneeRoutes,
} from 'services/AssigneeService';
import { ColumnProps } from 'antd/es/table';
import { useHistory, useLocation } from 'react-router';
import { useMarketplaces } from 'services/MarketplaceService';
import { useStores } from 'services/StoreService';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import { useDrivers } from 'services/DriverService';

import RouteModal from './route-modal';
import DateFormatter from './DateFormatter';
import DateDifference from './DateDifference';

const AssignedRoutes: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();

  const urlParams = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const query = useMemo(() => {
    return {
      page: urlParams.get('page') ? Number(urlParams.get('page')) : undefined,
      size: urlParams.get('size') ? Number(urlParams.get('size')) : undefined,
      driverId: urlParams.get('driverId')
        ? Number(urlParams.get('driverId'))
        : undefined,
      storeId: urlParams.get('storeId')
        ? Number(urlParams.get('storeId'))
        : undefined,
      dateFrom: urlParams.get('dateFrom'),
      dateTo: urlParams.get('dateTo'),
      marketplaceId: urlParams.get('marketplaceId')
        ? Number(urlParams.get('marketplaceId'))
        : undefined,
      route: urlParams.get('route')
        ? (urlParams.get('route') as string)
        : undefined,
    };
  }, [urlParams]);

  const { data: marketplaces } = useMarketplaces();
  const { data: stores } = useStores({
    pageNumber: 1,
    pageSize: 10000,
    search: '',
    marketplaceId: query.marketplaceId,
  });
  const { data: drivers } = useDrivers();

  const marketplacesOptions = useMarketplaceOptions(
    marketplaces?.content || [],
  );
  const storeOptions = useStoreOptions(stores?.content || []);

  const driversOptions = useMemo(() => {
    if (drivers) {
      return drivers.content.map((driver) => ({
        name: `${driver.name} ${driver.lastName}`,
        value: driver.id,
      }));
    }
    return [];
  }, [drivers]);

  const {
    data: routesResponse,
    isLoading,
    refetch: refetchRoutes,
  } = useAssigneeRoutes(query);

  const routes = useMemo(() => {
    if (routesResponse && routesResponse?.routes?.length > 0) {
      return routesResponse.routes;
    }
    return [];
  }, [routesResponse]);

  const { token } = theme.useToken();

  const handleOnRefresh = useCallback(() => {
    refetchRoutes();
  }, [refetchRoutes]);

  const handleCloseModal = useCallback(() => {
    const params = new URLSearchParams(search);
    params.delete('route');
    history.push({
      search: params.toString(),
    });
  }, [history, search]);

  const handleViewRouteDetail = useCallback(
    (routeId: string) => {
      const params = new URLSearchParams(search);
      params.set('route', routeId);
      history.push({
        search: params.toString(),
      });
    },
    [history, search],
  );

  const assignedStoreNameColumn: ColumnProps<IAssigneeRoute> = {
    title: 'Tienda',
    render(_, record) {
      return record.assigneeDriver.assignedStore.name;
    },
  };

  const routeStatusColumn: ColumnProps<IAssigneeRoute> = {
    title: 'Estado',
    dataIndex: 'status',
    render: (status: string) => {
      switch (status) {
        case RouteStatus.DRIVER_AT_STORE:
          return <Tag color="orange">Driver en tienda</Tag>;
        case RouteStatus.TRAIL_IN_PROGRESS:
          return <Tag color="blue">Ruta en progreso</Tag>;
        case RouteStatus.ROUTE_FINISHED:
          return <Tag color="green">Ruta finalizada</Tag>;
        default:
          return 'Desconocido';
      }
    },
  };

  const driverNameColumn: ColumnProps<IAssigneeRoute> = {
    title: 'Driver',
    render(_, record) {
      return record.assigneeDriver.name;
    },
  };
  const routeStartDate: ColumnProps<IAssigneeRoute> = {
    title: 'Inicio de ruta',
    render(_, record) {
      const startRoute = record.events.find(
        (event) => event.event === AssigneeRouteEventType.DRIVER_AT_STORE,
      );
      if (!startRoute) {
        return 'No disponible';
      }
      return DateFormatter(startRoute.timestamp);
    },
  };
  const shipmentsDeclaredColumn: ColumnProps<IAssigneeRoute> = {
    title: 'Paquetes',
    render(_, record) {
      return record.shipmentsDeclared;
    },
  };
  const timespanColumn: ColumnProps<IAssigneeRoute> = {
    title: 'Duración',
    render(_, record) {
      const startRoute = record.events.find(
        (event) => event.event === AssigneeRouteEventType.DRIVER_AT_STORE,
      );

      const endRoute = record.events.find(
        (event) => event.event === AssigneeRouteEventType.ROUTE_FINISHED,
      );

      if (!startRoute || !endRoute) {
        return 'No disponible';
      }

      return DateDifference(startRoute.timestamp, endRoute.timestamp);
    },
  };

  const viewRouteDetailColumn: ColumnProps<IAssigneeRoute> = {
    align: 'center',
    render: (_, record) => {
      if (record.status !== RouteStatus.ROUTE_FINISHED) {
        return (
          <Tooltip title="Ruta no finalizada">
            <EyeOutlined
              style={{ color: token.colorTextDisabled, fontSize: 18 }}
            />
          </Tooltip>
        );
      }
      return (
        <EyeOutlined
          onClick={() => handleViewRouteDetail(record.id)}
          style={{ color: token.colorPrimary, fontSize: 18, cursor: 'pointer' }}
        />
      );
    },
  };

  const dataColumns = [
    assignedStoreNameColumn,
    driverNameColumn,
    routeStatusColumn,
    routeStartDate,
  ];

  // Date, store and Driver Selectors
  const selectStoreCallback = useCallback(
    (storeSelected: string) => {
      const params = new URLSearchParams(search);
      if (storeSelected === '') {
        params.delete('storeId');
      } else {
        params.set('storeId', storeSelected);
      }
      history.push({
        search: params.toString(),
      });
    },
    [history, search],
  );
  const selectMarketplaceCallback = useCallback(
    (newMarketplace: string) => {
      const params = new URLSearchParams(search);
      if (newMarketplace === '') {
        params.delete('marketplaceId');
      } else {
        params.set('marketplaceId', newMarketplace);
      }
      history.push({
        search: params.toString(),
      });
    },
    [history, search],
  );

  const selectDriverCallback = useCallback(
    (driverSelected: string) => {
      const params = new URLSearchParams(search);
      if (driverSelected === '') {
        params.delete('driverId');
      } else {
        params.set('driverId', driverSelected);
      }
      history.push({
        search: params.toString(),
      });
    },
    [history, search],
  );

  const selectors = [
    {
      onChange: selectMarketplaceCallback,
      list: marketplacesOptions,
      placeholder: 'Marketplace',
      defaultValue: query.marketplaceId,
    },
    {
      onChange: selectStoreCallback,
      placeholder: 'Tienda',
      list: storeOptions,
      defaultValue: query.storeId,
    },
    {
      onChange: selectDriverCallback,
      placeholder: 'Driver',
      list: driversOptions,
      defaultValue: query.driverId,
    },
  ];

  const columnsLargeDevice = [
    ...dataColumns,
    shipmentsDeclaredColumn,
    timespanColumn,
    viewRouteDetailColumn,
  ];

  const columnsMediumDevice = [
    driverNameColumn,
    assignedStoreNameColumn,
    viewRouteDetailColumn,
  ];

  const columnsSmallDevice = [driverNameColumn, viewRouteDetailColumn];

  const handleUpdateDateRange = useCallback(
    (value: { from: string; to: string } | null) => {
      const params = new URLSearchParams(search);

      if (value === null) {
        params.delete('dateFrom');
        params.delete('dateTo');
        history.push({
          search: params.toString(),
        });
        return;
      }

      if (value.from) {
        params.set('dateFrom', value.from);
      } else {
        params.delete('dateFrom');
      }
      if (value.to) {
        params.set('dateTo', value.to);
      } else {
        params.delete('dateTo');
      }
      history.push({
        search: params.toString(),
      });
    },
    [history, search],
  );

  const rangePicker = {
    onDateSelection: handleUpdateDateRange,
    canBeCleared: true,
    required: {
      value: false,
      message: 'La fecha es requerida',
    },
  };

  const handleOnPaginate = useCallback(
    (pagination: { pageNumber: number; pageSize: number }) => {
      const params = new URLSearchParams(search);
      params.set('page', String(pagination.pageNumber + 1));
      params.set('size', String(pagination.pageSize));
      history.push({
        search: params.toString(),
      });
    },
    [history, search],
  );

  const paginate = useMemo(() => {
    return {
      pageNumber: routesResponse?.metadata.pageNumber
        ? routesResponse?.metadata.pageNumber - 1
        : 0,
      pageSize: query.size || 10,
      totalElements: routesResponse?.metadata.totalElements || 0,
    };
  }, [query.size, routesResponse]);

  return (
    <>
      <PaqueryTable
        loading={isLoading}
        header={{
          title: 'Rutas asignados',
          selectors,
          rangePicker,
          refresh: () => handleOnRefresh(),
        }}
        onChangePaginate={handleOnPaginate}
        dataSource={routes}
        paginate={paginate || DEFAULT_PAGINATE}
        dataColumns={dataColumns}
        colsForSmallDevice={columnsSmallDevice}
        colsForMediumDevice={columnsMediumDevice}
        colsForLargeDevice={columnsLargeDevice}
      />
      <RouteModal routeId={query.route} handleOnClose={handleCloseModal} />
    </>
  );
};

export default AssignedRoutes;
