import {
  CheckOutlined,
  LoadingOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Row, Spin, Statistic, Steps, theme } from 'antd';
import Modal from 'components/modal';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  AssigneeRouteEventType,
  IAssigneeRouteEvent,
  useAssigneeRoute,
  useDownloadReport,
} from 'services/AssigneeService';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
} from '@react-google-maps/api';

import './index.css';

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

interface Props {
  routeId?: string;
  handleOnClose: () => void;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const RouteModal: React.FC<Props> = ({ handleOnClose, routeId }) => {
  const { token } = theme.useToken();

  const mapRef = useRef<google.maps.Map | null>(null);
  const [activeMarker, setActiveMarker] = useState<string | null>(null);

  const { data: route, isLoading } = useAssigneeRoute(routeId);
  const {
    mutate: downloadReport,
    isPending: isDownloadingReport,
    isSuccess: isReportDownloaded,
    reset: resetDownloadReport,
  } = useDownloadReport();

  const startEvent = useMemo(() => {
    return route?.events.find(
      (event) => event.event === AssigneeRouteEventType.DRIVER_AT_STORE,
    );
  }, [route]);

  const endEvent = useMemo(() => {
    return route?.events.find(
      (event) => event.event === AssigneeRouteEventType.ROUTE_FINISHED,
    );
  }, [route]);

  // duration in format HH[h] mm[m]
  const routeDuration = useMemo(() => {
    if (startEvent && endEvent) {
      const start = dayjs(startEvent.timestamp);
      const end = dayjs(endEvent.timestamp);
      const duration = end.diff(start, 'minute');
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return `${hours}h ${minutes}m`;
    }
    return '-';
  }, [startEvent, endEvent]);

  const events = useMemo(() => {
    return (
      route?.events.sort(
        (a, b) => +new Date(a.timestamp) - +new Date(b.timestamp),
      ) || []
    );
  }, [route]);

  const getStepClass = useCallback((event: IAssigneeRouteEvent) => {
    if (event.event === AssigneeRouteEventType.FAILED_DELIVERY) {
      return 'delivery-try';
    }
    if (event.event === AssigneeRouteEventType.SUCCESS_DELIVERY) {
      return 'delivered';
    }
    if (event.event === AssigneeRouteEventType.ROUTE_FINISHED) {
      return 'route-end';
    }
    if (
      event.event === AssigneeRouteEventType.DRIVER_AT_STORE ||
      event.event === AssigneeRouteEventType.TRAIL_START
    ) {
      return 'route-start';
    }
    return '';
  }, []);

  const center = useMemo(() => {
    const bounds = new google.maps.LatLngBounds();

    events.forEach((event) => {
      bounds.extend(new google.maps.LatLng(event.geo.lat, event.geo.lng));
    });

    return bounds.getCenter().toJSON();
  }, [events]);

  const onLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  const path = useMemo(() => {
    if (route?.geolocations && route.geolocations.length > 0) {
      return route.geolocations
        .slice()
        .sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
        ) // Ordenar por timestamp
        .map((geo) => ({
          lat: geo.coords.latitude,
          lng: geo.coords.longitude,
        }));
    }
    return (
      events
        .slice()
        .sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
        ) // Ordenar por timestamp
        .map((event) => ({
          lat: event.geo.lat,
          lng: event.geo.lng,
        })) || []
    );
  }, [route, events]);

  const getEventColor = useCallback(
    (eventType: AssigneeRouteEventType) => {
      switch (eventType) {
        case AssigneeRouteEventType.SUCCESS_DELIVERY:
          return token.colorSuccess;
        case AssigneeRouteEventType.FAILED_DELIVERY:
          return token.colorWarning;
        case AssigneeRouteEventType.DRIVER_AT_STORE:
          return 'blue';
        case AssigneeRouteEventType.TRAIL_START:
          return 'blue';
        case AssigneeRouteEventType.ROUTE_FINISHED:
          return 'red';
        default:
          return 'blue';
      }
    },
    [token],
  );

  const getMarkerIcon = useCallback(
    (event: IAssigneeRouteEvent) => {
      const color = getEventColor(event.event);

      let scale = 10;

      switch (event.event) {
        case AssigneeRouteEventType.ROUTE_FINISHED:
          scale = 15;
          break;
        case AssigneeRouteEventType.DRIVER_AT_STORE:
          scale = 20;
          break;
        default:
          break;
      }

      return {
        path: google.maps.SymbolPath.CIRCLE, // Puedes usar formas predefinidas como CIRCLE, BACKWARD_OPEN_ARROW, etc.
        fillColor: color,
        fillOpacity: 1,
        scale, // Tamaño del marcador
        strokeColor: 'white',
        strokeWeight: 2,
      };
    },
    [getEventColor],
  );

  const handleMarkerClick = useCallback(
    (markerId: string) => {
      if (markerId === activeMarker) {
        return setActiveMarker(null); // Cierra el InfoWindow si se vuelve a hacer clic en el marcador activo
      }
      setActiveMarker(markerId); // Abre el InfoWindow para el marcador clickeado
      return true;
    },
    [activeMarker],
  );

  const handleFocusMarker = useCallback(
    (event: IAssigneeRouteEvent) => {
      if (!mapRef.current) {
        return;
      }

      mapRef.current.setZoom(17);
      mapRef.current.panTo({
        lat: event.geo.lat,
        lng: event.geo.lng,
      });

      setActiveMarker(event.timestamp);
    },
    [mapRef, setActiveMarker],
  );

  const getEventDescription = useCallback((event: IAssigneeRouteEvent) => {
    switch (event.event) {
      case AssigneeRouteEventType.DRIVER_AT_STORE:
        return 'El driver llego a la tienda';
      case AssigneeRouteEventType.FAILED_DELIVERY:
        return 'Intento de entrega';
      case AssigneeRouteEventType.SUCCESS_DELIVERY:
        return 'Entrega exitosa';
      case AssigneeRouteEventType.TRAIL_START:
        return 'Inicio de la ruta';
      case AssigneeRouteEventType.ROUTE_FINISHED:
        return 'Fin de la ruta';
      default:
        return 'Desconocido';
    }
  }, []);

  const getStepTitle = useCallback(
    (event: IAssigneeRouteEvent) => {
      const ViewOnMapComponent = (
        <>
          <span
            role="button"
            tabIndex={0}
            style={{
              color: token['blue-5'],
              cursor: 'pointer',
              fontSize: '12px',
              textDecoration: 'underline',
            }}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onKeyDown={() => {}}
            onClick={() => handleFocusMarker(event)}
          >
            Ver
          </span>
        </>
      );
      let TitleComponent;
      switch (event.event) {
        case AssigneeRouteEventType.DRIVER_AT_STORE:
          TitleComponent = <strong>El driver llego a la tienda</strong>;
          break;
        case AssigneeRouteEventType.FAILED_DELIVERY:
          TitleComponent = <strong>Intento de entrega</strong>;
          break;
        case AssigneeRouteEventType.SUCCESS_DELIVERY:
          TitleComponent = <strong>Entrega exitosa</strong>;
          break;
        case AssigneeRouteEventType.TRAIL_START:
          TitleComponent = <strong>Inicio de la ruta</strong>;
          break;
        case AssigneeRouteEventType.ROUTE_FINISHED:
          TitleComponent = <strong>Fin de la ruta</strong>;
          break;
        default:
          TitleComponent = <strong>Desconocido</strong>;
      }
      return (
        <Row gutter={8}>
          <Col>{TitleComponent}</Col>
          <Col>{ViewOnMapComponent}</Col>
        </Row>
      );
    },
    [handleFocusMarker, token],
  );

  const getStepDescription = useCallback((event: IAssigneeRouteEvent) => {
    const DateComponent = (
      <>
        <p>{dayjs(event.timestamp).format('DD/MM/YYYY HH:mm')}</p>
      </>
    );
    let DescriptionComponent;
    switch (event.event) {
      case AssigneeRouteEventType.FAILED_DELIVERY:
        DescriptionComponent = (
          <p>
            <strong>ID:</strong> {event.shipmentId}
          </p>
        );
        break;
      case AssigneeRouteEventType.SUCCESS_DELIVERY:
        DescriptionComponent = (
          <p>
            <strong>ID:</strong> {event.shipmentId}
          </p>
        );
        break;
      default:
        DescriptionComponent = null;
    }
    return (
      <div>
        {DescriptionComponent}
        {DateComponent}
      </div>
    );
  }, []);

  return (
    <Modal
      title={
        <span style={{ fontSize: '22px', fontWeight: 'bold' }}>
          Resumen de ruta
        </span>
      }
      style={{
        minWidth: '90%',
      }}
      open={routeId}
      onCancel={handleOnClose}
      centered
      footer={[
        <Button key="back" onClick={handleOnClose}>
          Cerrar
        </Button>,
      ]}
    >
      <Spin indicator={antIcon} tip="Cargando la ruta..." spinning={isLoading}>
        <Row gutter={16} style={{ maxHeight: '100%', display: 'flex' }}>
          <Col xs={24} style={{ maxHeight: '100%' }} xl={8}>
            <Row gutter={[16, 16]}>
              <Row style={{ maxHeight: '50%', width: '100%' }}>
                <Card
                  title="Registro de eventos"
                  style={{ width: '100%' }}
                  styles={{
                    body: {
                      overflow: 'auto',
                      height: '30vh',
                    },
                  }}
                >
                  <Steps
                    current={2}
                    progressDot={(dot) => dot}
                    direction="vertical"
                  >
                    {events.map((event) => (
                      <Steps.Step
                        className={getStepClass(event)}
                        key={event.timestamp}
                        title={getStepTitle(event)}
                        description={getStepDescription(event)}
                        status="finish"
                      />
                    ))}
                  </Steps>
                </Card>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Statistic
                        title="Tienda"
                        value={route?.assigneeDriver.assignedStore.name ?? '-'}
                        valueStyle={{ fontSize: '1.25rem' }}
                        groupSeparator=" "
                      />
                    </Col>
                    <Col span={24}>
                      <Statistic
                        title="Driver"
                        value={route?.assigneeDriver.name ?? '-'}
                        valueStyle={{ fontSize: '1.25rem' }}
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        title="Inicio de ruta"
                        value={
                          startEvent?.timestamp
                            ? dayjs(startEvent?.timestamp).format(
                                'DD/MM/YYYY HH:mm',
                              )
                            : '-'
                        }
                        valueStyle={{ fontSize: '1.25rem' }}
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        title="Fin de ruta"
                        value={
                          endEvent?.timestamp
                            ? dayjs(endEvent?.timestamp).format(
                                'DD/MM/YYYY HH:mm',
                              )
                            : '-'
                        }
                        valueStyle={{ fontSize: '1.25rem' }}
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        title="Duracion"
                        value={routeDuration}
                        valueStyle={{ fontSize: '1.25rem' }}
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        title="Distancia declarada"
                        value={`${route?.kilometersDeclared || 0} km`}
                        valueStyle={{ fontSize: '1.25rem' }}
                      />
                    </Col>
                    <Col span={8}>
                      <Statistic
                        title="Paquetes declarados"
                        value={route?.shipmentsDeclared || 0}
                        valueStyle={{ fontSize: '1.25rem' }}
                      />
                    </Col>
                    <Col span={8}>
                      <Statistic
                        title="Paquetes entregados"
                        value={route?.shipmentsDelivered || 0}
                        valueStyle={{ fontSize: '1.25rem' }}
                      />
                    </Col>
                    <Col span={8}>
                      <Statistic
                        title="Paquetes no entregados"
                        value={route?.shipmentsFailedToDeliver || 0}
                        valueStyle={{ fontSize: '1.25rem' }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row align="middle" justify="end" style={{ width: '100%' }}>
                <Col>
                  <Button
                    type="primary"
                    icon={
                      isReportDownloaded ? (
                        <CheckOutlined />
                      ) : (
                        <VerticalAlignBottomOutlined />
                      )
                    }
                    disabled={isReportDownloaded || isDownloadingReport}
                    onClick={() => {
                      if (route) {
                        downloadReport(route, {
                          onSettled: () => {
                            setTimeout(() => {
                              resetDownloadReport();
                            }, 3000);
                          },
                        });
                      }
                    }}
                    loading={isDownloadingReport}
                  >
                    {isReportDownloaded
                      ? 'Reporte generado'
                      : 'Descargar reporte'}
                  </Button>
                </Col>
              </Row>
            </Row>
          </Col>
          <Col xs={24} style={{ maxHeight: '100%' }} xl={16}>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={15}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {path.length > 0 && (
                <Polyline
                  path={path}
                  options={{
                    strokeColor: '#0e53ff',
                    strokeOpacity: 0.8,
                    strokeWeight: 5,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    visible: true,
                    zIndex: 1,
                  }}
                />
              )}
              {events.map((event, index) => (
                <Marker
                  key={event.timestamp + event.shipmentId}
                  position={{ lat: event.geo.lat, lng: event.geo.lng }}
                  label={{
                    text: `${index + 1}`,
                    color: 'white',
                    fontSize: '12px',
                  }}
                  title={event.description}
                  icon={getMarkerIcon(event)} // Asignar color del evento
                  onClick={() => handleMarkerClick(event.timestamp)} // Mostrar el InfoWindow cuando se haga clic
                >
                  {activeMarker === event.timestamp && (
                    <InfoWindow
                      position={{ lat: event.geo.lat, lng: event.geo.lng }}
                      onCloseClick={() => setActiveMarker(null)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{ paddingRight: '10px' }}>
                          <p>
                            <strong>Descripción:</strong>{' '}
                            {getEventDescription(event)}
                          </p>
                          {event.shipmentId && (
                            <p>
                              <strong>ID:</strong> {event.shipmentId}
                            </p>
                          )}
                          <p>
                            <strong>Fecha:</strong>{' '}
                            {dayjs(event.timestamp).format('DD/MM/YYYY HH:mm')}
                          </p>
                        </div>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            </GoogleMap>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

const RouteModalHOC: React.FC<Props> = (props) => {
  return <RouteModal {...props} />;
};

export default RouteModalHOC;
