/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Alert,
  Button,
  Col,
  Input,
  notification,
  Row,
  Select,
  Statistic,
  theme,
  Typography,
} from 'antd';
import Modal from 'components/modal';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useDrivers } from 'services/DriverService';
import { useStores } from 'services/StoreService';
import { useMarketplaces } from 'services/MarketplaceService';
import {
  AssigneeStore,
  useCreateAssigneeDriver,
} from 'services/AssigneeService';
import zod from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditOutlined } from '@ant-design/icons';
import MapWithMarker from '../GoogleMap/GoogleMap';

interface Props {
  handleClose: () => void;
  open: boolean;
}

interface CreateAssigneeDriverForm {
  store?: {
    id: number;
    name: string;
    address?: string;
    geo?: {
      lat: number;
      lng: number;
    };
  };

  driver: {
    id: number;
    name: string;
  };

  logisticOperatorId: number;
}

const validationSchema = zod.object({
  store: zod.object(
    {
      id: zod.number(),
      name: zod.string(),
      address: zod.string(),
      geo: zod.object(
        {
          lat: zod.number(),
          lng: zod.number(),
        },
        { message: 'La tienda seleccionada no tiene una posición geográfica' },
      ),
    },
    {
      message: 'Selecciona una tienda',
    },
  ),
  driver: zod.object(
    {
      id: zod.number(),
      name: zod.string(),
    },
    {
      message: 'Selecciona un driver',
    },
  ),
});

export const AssigneeDriverModal: React.FC<Props> = ({ handleClose, open }) => {
  const [marketplaceId, setMarketplaceId] = useState<number | undefined>(
    undefined,
  );
  const [editingAddress, setEditingAddress] = useState(false);

  const { token } = theme.useToken();

  const { mutate: createAssigneeDriver, isPending: isLoadingCreation } =
    useCreateAssigneeDriver();

  const {
    control,
    formState: { errors },
    reset,
    trigger,
    setValue,
    getValues,
  } = useForm<CreateAssigneeDriverForm>({
    resolver: zodResolver(validationSchema),
  });

  const formSelectedStore = useWatch({
    control,
    name: 'store',
  });

  const { data: drivers } = useDrivers();
  const { data: marketplaces } = useMarketplaces();
  const { data: stores } = useStores({
    marketplaceId,
  });

  const driversOptions = useMemo(() => {
    if (drivers) {
      return drivers.content.map((driver) => ({
        label: `${driver.name} ${driver.lastName}`,
        value: driver.id,
      }));
    }
    return [];
  }, [drivers]);

  const storesOptions = useMemo(() => {
    if (stores) {
      return stores.content.map((store) => ({
        label: store.name,
        value: store.id,
      }));
    }
    return [];
  }, [stores]);

  const marketplacesOptions = useMemo(() => {
    if (marketplaces) {
      return marketplaces.content.map((marketplace) => ({
        label: marketplace.name,
        value: marketplace.id,
      }));
    }
    return [];
  }, [marketplaces]);

  const handleCreateAssigneeDriver = useCallback(async () => {
    const values = getValues();
    const isValid = await trigger();
    if (!isValid) return;
    if (!values.store) return;
    if (!values.store.geo) return;
    if (!values.store.address) return;

    createAssigneeDriver(
      {
        store: values.store as AssigneeStore,
        driver: {
          id: values.driver.id.toString(),
          name: values.driver?.name,
        },
      },
      {
        onSuccess: () => {
          notification.success({
            message: 'Driver asignado',
            description: 'El driver ha sido asignado correctamente',
          });
          handleClose();
        },
        onError: (error) => {
          notification.error({
            message: 'Ha ocurrido un error al asignar el driver',
            description: error.message,
          });
        },
      },
    );
  }, [createAssigneeDriver, handleClose, trigger, getValues]);

  const storeHasGeoposition = useMemo(() => {
    return (
      formSelectedStore?.geo?.lat !== 0 &&
      formSelectedStore?.geo?.lng !== 0 &&
      formSelectedStore?.address !== ''
    );
  }, [formSelectedStore]);

  const handleResetForm = useCallback(() => {
    setMarketplaceId(undefined);
    setEditingAddress(false);
    reset();
  }, [reset]);

  const handleCloseModal = useCallback(() => {
    handleResetForm();
    handleClose();
  }, [handleClose, handleResetForm]);

  const setGeoPosition = useCallback(
    (lat: number, lng: number) => {
      if (!formSelectedStore) return;

      setValue('store', {
        ...formSelectedStore,
        geo: {
          lat,
          lng,
        },
      });
    },
    [formSelectedStore, setValue],
  );

  const handleSetAddress = useCallback(
    (address: string) => {
      if (!formSelectedStore) return;

      setValue('store', {
        ...formSelectedStore,
        address,
      });
    },
    [formSelectedStore, setValue],
  );

  return (
    <Modal
      title={
        <span style={{ fontSize: '22px', fontWeight: 'bold' }}>
          Nuevo driver asignado
        </span>
      }
      open={open}
      style={{ minWidth: '40%' }}
      onCancel={handleCloseModal}
      centered
      footer={[
        <Button key="back" onClick={handleCloseModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleCreateAssigneeDriver}
          loading={isLoadingCreation}
        >
          Crear
        </Button>,
      ]}
    >
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Controller
            name="driver"
            control={control}
            render={({ field }) => (
              <Row>
                <Col span={24}>
                  <label htmlFor="driver">Driver</label>
                </Col>

                <Col span={24}>
                  <Select
                    style={{
                      width: '100%',
                    }}
                    id="driver"
                    value={field.value?.id}
                    showSearch
                    placeholder="Selecciona un Driver"
                    options={driversOptions}
                    onChange={(value) => {
                      const driver = drivers?.content.find(
                        (d) => d.id === value,
                      );

                      field.onChange({
                        id: driver?.id,
                        name: `${driver?.name} ${driver?.lastName}`,
                      });
                    }}
                  />
                  {errors.driver?.message && (
                    <Typography.Text type="danger">
                      {errors.driver?.message}
                    </Typography.Text>
                  )}
                </Col>
              </Row>
            )}
          />
        </Col>

        <Col span={24}>
          <Controller
            name="store"
            control={control}
            rules={{
              required: 'Selecciona una tienda',
              validate: {
                hasGeoPosition: () => {
                  if (!storeHasGeoposition) {
                    return 'La tienda seleccionada no tiene una posición geográfica';
                  }
                  return true;
                },
              },
            }}
            render={({ field }) => (
              <>
                <Row gutter={8}>
                  <Col span={12}>
                    <Row>
                      <Col xs={24} xl={12}>
                        <label htmlFor="mkp">Marketplace</label>
                      </Col>
                      <Col span={24}>
                        <Select
                          id="mkp"
                          style={{
                            width: '100%',
                          }}
                          showSearch
                          placeholder="Selecciona una Tienda"
                          value={marketplaceId}
                          onChange={(value) => {
                            setMarketplaceId(value);
                            setValue('store', undefined);
                          }}
                          options={marketplacesOptions}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Row>
                      <Col span={24}>
                        <label htmlFor="store">Tienda</label>
                      </Col>
                      <Col span={24}>
                        <Select
                          id="store"
                          style={{
                            width: '100%',
                          }}
                          showSearch
                          value={formSelectedStore?.id}
                          placeholder="Selecciona una Tienda"
                          options={storesOptions}
                          onChange={(value) => {
                            const store = stores?.content.find(
                              (s) => s.id === value,
                            );

                            field.onChange({
                              id: store?.id,
                              name: store?.name,
                              address: store?.address,
                            });
                          }}
                        />
                        {errors.store?.message && (
                          <Typography.Text type="danger">
                            {errors.store?.message}
                          </Typography.Text>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          />
        </Col>

        <Col span={24}>
          {formSelectedStore ? (
            <>
              <Row>
                <Statistic
                  title="Empresa"
                  value="Nespresso"
                  valueStyle={{
                    fontSize: '16px',
                    marginTop: '-6px',
                  }}
                />
              </Row>

              <Row style={{ width: '100%' }}>
                <Statistic
                  valueRender={(value) => {
                    if (editingAddress) {
                      return (
                        <Row justify="end" gutter={[4, 4]}>
                          <Col span={24}>
                            <Input
                              style={{ width: '100%' }}
                              placeholder="Direccion"
                              defaultValue={formSelectedStore.address}
                              onBlur={(e) => handleSetAddress(e.target.value)}
                            />
                          </Col>
                          <Col>
                            <Button
                              type="primary"
                              size="small"
                              onClick={() => setEditingAddress(false)}
                            >
                              Guardar
                            </Button>
                          </Col>
                        </Row>
                      );
                    }

                    return (
                      <div>
                        {value}{' '}
                        <EditOutlined
                          style={{
                            color: token.colorPrimary,
                          }}
                          onClick={() => setEditingAddress(true)}
                        />
                      </div>
                    );
                  }}
                  title="Direccion"
                  value={formSelectedStore.address}
                  style={{ width: '100%' }}
                  valueStyle={{
                    fontSize: '16px',
                    marginTop: '-6px',
                  }}
                />
              </Row>

              <Row
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  height: 200,
                  backgroundColor: '#eaeaea',
                }}
              >
                <MapWithMarker
                  lat={1}
                  lng={1}
                  address={formSelectedStore?.address || ''}
                  handleOnGeoChange={setGeoPosition}
                />
              </Row>
            </>
          ) : (
            <Alert
              message="Selecciona una tienda"
              description="Selecciona una tienda para ver una vista previa de la dirección"
              type="info"
            />
          )}
        </Col>
      </Row>

      {errors.store?.geo?.message && (
        <Row style={{ marginTop: 8 }}>
          <Alert message={errors.store?.geo?.message} type="error" showIcon />
        </Row>
      )}
    </Modal>
  );
};
